import { executeDeleteData, executeGetData, executePostData, executePutData } from "./ApiBase";
import { ConfigUrl } from "../auth/OidcConfig";

export const getCategoryMaster = async (accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/categoryMaster/getAllCategories`;
    return await executeGetData(url, accessToken);
  };
  
  export const addCategory= async (payload: any, accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/categoryMaster/createCategory`;
    return await executePostData(url, payload, accessToken);
  };
  
  export const updateCategory= async (payload: any,id:any, accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/categoryMaster/updateCategory/id/${id}`;
    return await executePutData(url, payload, accessToken);
  };
  export const deleteCategory= async (id:any, accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/categoryMaster/id/${id}`;
    return await executeDeleteData(url, accessToken);
  };
  export const getAllCategoryMaster = async (accessToken: any, page:any, size:any) => {
    const url = `${ConfigUrl.baseURL}/categoryMaster/getAllCategory?page=${page}&size=${size}`;
    return await executeGetData(url, accessToken);
  };