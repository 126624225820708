import React from 'react';
import { Box, Button, FormControl, FormControlLabel, Grid, IconButton, Radio, RadioGroup, TextField } from '@mui/material';
import { CodeList, CodeListItem, ItemDef } from '../../../interface/SdmInterfaces';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

interface Props {
    index: string; // OID of the item
    questions: ItemDef[]; // Assuming questions are of type ItemDef
    setQuestions: React.Dispatch<React.SetStateAction<ItemDef[]>>; // Update type for questions
    codeList: CodeList[];
    setCodeList: React.Dispatch<React.SetStateAction<CodeList[]>>;
    setCodeOId: any;
}

const SingleChoiceComponent: React.FC<Props> = ({ index, questions, setQuestions, codeList, setCodeList, setCodeOId }) => {

    const handleOptionChange = (optionIndex: number, e: React.ChangeEvent<HTMLInputElement>) => {
        const newCodeList = [...codeList];
        const question = questions.find(q => q.OID === index);

        if (question) {
            const codeListIndex = newCodeList.findIndex(cl => cl.OID === question.CodeListRef?.CodeListOID);
            if (codeListIndex !== -1) {
                const updatedItems = newCodeList[codeListIndex].CodeListItem.map((item, idx) =>
                    idx === optionIndex
                        ? { ...item, Decode: { TranslatedText: { ...item.Decode.TranslatedText, text: e.target.value } } }
                        : item
                );
                newCodeList[codeListIndex].CodeListItem = updatedItems;
                setCodeList(newCodeList);

                setCodeOId(prev => {
                    const newSet = new Set(prev);
                    newSet.add(newCodeList[codeListIndex].OID);
                    return newSet;
                });
            }
        }
    };

    const addOption = () => {
        const newCodeList = [...codeList];
        const question = questions.find(q => q.OID === index);
        console.log(questions)
        if (question) {

            const codeListIndex = newCodeList.findIndex(cl => cl.OID === question.CodeListRef?.CodeListOID);
            if (codeListIndex !== -1) {
                const currentOptions = newCodeList[codeListIndex].CodeListItem || [];
                const newOption = `Option ${currentOptions.length + 1}`;

                newCodeList[codeListIndex].CodeListItem = [
                    ...currentOptions,
                    {
                        Decode: {
                            TranslatedText: {
                                lang: 'en',
                                text: newOption,
                            },
                        },
                        CodedValue: (currentOptions.length + 1).toString(),
                        IsEnabled: false
                    }
                ];

                setCodeList(newCodeList);
                setCodeOId(prev => {
                    const newSet = new Set(prev);
                    newSet.add(newCodeList[codeListIndex].OID);
                    console.log(newSet)
                    return newSet;
                });
            }
        }
    };

    const removeOption = (optionIndex: number) => {
        const newCodeList = [...codeList];
        const question = questions.find(q => q.OID === index);

        if (question) {
            const codeListIndex = newCodeList.findIndex(cl => cl.OID === question.CodeListRef?.CodeListOID);
            if (codeListIndex !== -1) {
                const updatedItems = newCodeList[codeListIndex].CodeListItem.filter((_, idx) => idx !== optionIndex);
                newCodeList[codeListIndex].CodeListItem = updatedItems;
                setCodeList(newCodeList);
                setCodeOId(prev => {
                    const newSet = new Set(prev);
                    newSet.add(newCodeList[codeListIndex].OID);
                    return newSet;
                });
            }
        }
    };

    return (
        <Box sx={{ width: '100%', typography: 'body1', marginLeft: "0px" }}>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={12}>
                    <FormControl>
                        <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group">
                            {codeList
                                .find(cl => cl.OID === questions.find(q => q.OID === index)?.CodeListRef?.CodeListOID)
                                ?.CodeListItem?.map((option: CodeListItem, optionIndex: number) => (
                                    <Box key={`${option.CodedValue}-${optionIndex}`} display="flex" alignItems="center">
                                        <FormControlLabel
                                            value={option.CodedValue}
                                            control={<Radio />}
                                            label={
                                                <>
                                                    <TextField
                                                        label={`Option ${optionIndex + 1}`}
                                                        variant="filled"
                                                        size="small"
                                                        value={option.Decode.TranslatedText.text}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOptionChange(optionIndex, e)}
                                                    />
                                                    <IconButton onClick={() => removeOption(optionIndex)}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </>
                                            }
                                        />
                                    </Box>
                                ))}
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Button className="primaryTonalButton" size="small" onClick={addOption}><AddIcon />Add Option</Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SingleChoiceComponent;
